<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      agents: [],
      accountsPayable: [],
      advancedPayments: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchMode: false,
      searchModel: "",
    };
  },
  methods: {
    addAgent() {
      let locale = this.$i18n.locale;
      var data = {
        title: "popups.add",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "agents.name",
          },
          {
            model: "name_en",
            type: "text",
            label: "agents.name_en",
          },
          {
            model: "payable_subaccount_id",
            type: "select",
            options: (() => {
              return this.accountsPayable.map((data) => {
                return {
                  value: data.sas_id,
                  label: locale == "ar" ? data.sas_name : data.sas_name_en,
                };
              });
            })(),
            label: "agents.payable_account",
          },
          {
            model: "prepayments_subaccount_id",
            type: "select",
            options: (() => {
              return this.advancedPayments.map((data) => {
                return {
                  value: data.sas_id,
                  label: locale == "ar" ? data.sas_name : data.sas_name_en,
                };
              });
            })(),
            label: "agents.prepayments_account",
          },
          {
            model: "address",
            type: "text",
            label: "agents.address",
          },
          {
            model: "address_en",
            type: "text",
            label: "agents.address_en",
          },
          {
            model: "phone",
            type: "text",
            label: "agents.phone",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.code += " sup";
              this.http.post("agents", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editAgent(app) {
      let locale = this.$i18n.locale;
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "name",
            type: "text",
            value: app.name,
            label: "agents.name",
          },
          {
            model: "name_en",
            type: "text",
            value: app.name_en,
            label: "agents.name_en",
          },
          {
            model: "payable_subaccount_id",
            type: "select",
            options: (() => {
              return this.accountsPayable.map((data) => {
                return {
                  value: data.sas_id,
                  label: locale == "ar" ? data.sas_name : data.sas_name_en,
                };
              });
            })(),
            label: "agents.payable_account",
          },
          {
            model: "prepayments_subaccount_id",
            type: "select",
            options: (() => {
              return this.advancedPayments.map((data) => {
                return {
                  value: data.sas_id,
                  label: locale == "ar" ? data.sas_name : data.sas_name_en,
                };
              });
            })(),
            label: "agents.prepayments_account",
          },
          {
            model: "address",
            type: "text",
            value: app.address,
            label: "agents.address",
          },
          {
            model: "address_en",
            type: "text",
            value: app.address_en,
            label: "agents.address_en",
          },
          {
            model: "phone",
            type: "text",
            value: app.phone,
            label: "agents.phone",
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("agents/", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    deleteAgent(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.deletemsg",
      };
      this.popup.confirm(data).then((res) => {
        if (res) {
          this.http.delete("agents", app.id).then(() => {
            this.get(this.page);
          });
        }
      });
    },
    search() {
      this.searchMode = true;
      this.tot_pages = 0;
      this.http
        .post("agents/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.agents = res.data;
          this.agents.forEach((agent, index) => {
            let code = (this.page - 1) * this.limit + index + 1;
            agent.code =
              "SUP-" +
              (code < 10
                ? "000" + code
                : code < 100
                ? "00" + code
                : code < 1000
                ? "0" + code
                : code);
          });
        });
    },
    cancelSearchMode() {
      this.searchModel = "";
      this.searchMode = false;
      this.get(this.page);
    },
    getAccounts() {
      this.http.get("agents/accounts/subledger").then((res) => {
        if (res.status) {
          this.accountsPayable = res.data[0]?.sub_accounts;
          this.advancedPayments = res.data[1]?.sub_accounts;
        }
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post("agents/pagination", {
          limit: this.limit,
          page: page,
          id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.agents = res.data;
          this.agents.forEach((agent, index) => {
            let code = (this.page - 1) * this.limit + index + 1;
            agent.code =
              "SUP-" +
              (code < 10
                ? "000" + code
                : code < 100
                ? "00" + code
                : code < 1000
                ? "0" + code
                : code);
          });
        });
    },
  },
  created() {
    this.get(1);
    this.getAccounts();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.payments.sub.suppliers')"
    />
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="search-box chat-search-box" style="width: 250px">
                <div class="position-relative">
                  <input
                    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
                    @keyup="search()"
                    type="text"
                    class="form-control fa-lg text-light"
                    :placeholder="$t('salaryItems.search')"
                    v-model="searchModel"
                    style="background-color: #2a3042 !important"
                  />
                  <i class="bx bx-search-alt search-icon text-light"></i>
                  <button
                    @click="cancelSearchMode()"
                    v-if="searchMode"
                    class="btn-close text-light"
                    style="position: absolute; top: 12px; left: 250px"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-6" style="display: flex; justify-content: flex-end">
              <button
                type="button"
                @click="addAgent()"
                class="btn btn-light float-end mb-4"
              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("popups.code") }}</th>
                  <th scope="col">{{ $t("agents.name") }}</th>
                  <th scope="col">{{ $t("agents.address") }}</th>
                  <th scope="col">{{ $t("agents.phone") }}</th>
                  <th scope="col">{{ $t("agents.created") }}</th>
                  <th scope="col">{{ $t("agents.updated") }}</th>
                  <th scope="col">{{ $t("agents.operations") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, index) in agents" :key="app" class="">
                  <td>{{ index + 1 }}</td>
                  <td>{{ app?.code }}</td>
                  <td>{{ $i18n.locale == "ar" ? app?.name : app?.name_en }}</td>
                  <!-- <td>
                    {{
                      (
                        parseFloat(app?.totalCredit) -
                        parseFloat(app?.totalDebit)
                      ).toLocaleString()
                    }}
                  </td> -->
                  <td>
                    {{ $i18n.locale == "ar" ? app?.address : app?.address_en }}
                  </td>
                  <td>{{ app?.phone }}</td>
                  <td>{{ app.created?.split("T")[0] }}</td>
                  <td>{{ app.updated?.split("T")[0] }}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      style="margin-inline-end: 4px"
                      @click="editAgent(app)"
                    >
                      {{ $t("popups.edit") }}
                    </button>

                    <button
                      class="btn btn-danger btn-sm"
                      href="javascript:void(0)"
                      role="button"
                      @click="deleteAgent(app)"
                    >
                      {{ $t("popups.delete") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- end  table -->

    <!--   Purchases  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->
  </Layout>
</template>
